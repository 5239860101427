import styledSanitize from "styled-sanitize"
import { createGlobalStyle } from "@nfront/global-styles"
import csso from "csso"
import { mq, site } from "./variables"
import * as fonts from "../assets/fonts"

const { css } = csso.minify(`
  ${styledSanitize}

  @font-face {
    font-family: 'MierB Book';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src: url('${fonts.MierBBookWoff2}') format('woff2'),
         url('${fonts.MierBBookWoff}') format('woff');
  }

  * {
    outline: 0;
  }

  body {
    transition: background 400ms;
    font-family: 'MierB Book', sans-serif;
    font-size: 20px;
    line-height: 1.38;
    padding: 26px ${site.gutter.s}px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: inherit;
    text-decoration: none;

  }

  p {
    margin: 0 0 1.38em;
  }

  ul {
    margin: 0 0 2em;
  }

  li {
    margin: 0 0 0.7em;
  }

  p a,
  a.link {
    display: inline-block;
    box-shadow: inset 0 -2px rgba(0, 0, 0, 0.1);
    transition: box-shadow 200ms;
  }

  p a:hover,
  a.link:hover {
    box-shadow: inset 0 -2px rgba(0, 0, 0, 1);
  }

  h1, h2, h3, h4 {
    font-weight: normal;
    margin: 0;
  }

  h1, h2 {
    font-size: 52px;
    letter-spacing: -0.03em;
    line-height: 0.905;
    margin: 0 0 0.5em;
  }

  h3 {
    font-size: 32px;
    line-height: 1.1;
    margin: 0 0 0.5em;
  }

  button, label {
    cursor: pointer;
  }

  .js .reveal {
    opacity: 0;
    transform: translate(0, 30px);
    will-change: opacity, transform;
  }

  @media ${mq.gte("m")} {
    body {
      padding-top: 40px;
    }
  }

  @media ${mq.gte("l")} {
    body {
      padding: 60px ${site.gutter.l}px 45px;
    }

    h1, h2 {
      font-size: 80px;
      margin: 0 0 0.7em;
    }
  }

  .fade-enter {
    opacity: 0;
    transform: translateY(-50%);
  }

  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: 200ms ease;
  }

  .fade-exit {
    opacity: 0;
    transform: translateY(50%);
    transition: 200ms ease;
  }

`)

export default createGlobalStyle`${css}`
